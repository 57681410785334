import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { routeMap } from '/routeMap'

export function extractLinkInfo(obj) {
  if (!obj) return {}

  if (Array.isArray(obj)) return extractLinkInfo(obj[0])

  if (obj.ref && obj._type === 'internalLink') return {
    href: determineDocumentPathSync({ document: obj.ref, routeMap }),
    label: obj.label ?? obj.title
  }

  if (obj._type === 'externalLink') return obj

  return {}
}
