import { PortableText } from '@portabletext/react'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { stringifyQueryString } from '@kaliber/sanity-routing/queryString'
import { HeadingMd, HeadingLg } from '/features/buildingBlocks/Heading'
import { LinkUnderlined } from '/features/buildingBlocks/Link'
import { TextMd } from '/features/buildingBlocks/Text'
import { routeMap } from '/routeMap'
import { useLanguage } from '/machinery/I18n'
import { ensureArray } from '/machinery/ensureArray'

import styles from './PortableText.css'

export function PortableTextMainContent({ value, layoutClassName = undefined }) {
  return (
    <PortableTextBase
      className={cx(styles.componentMainContent, layoutClassName)}
      components={componentsMainContent}
      {...{ value }}
    />
  )
}

function PortableTextBase({ value, components, className }) {
  return <div {...{ className }}><PortableText {...{ value, components }} /></div>
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsMainContent = {
  block: {
    normal: ({ children }) => <TextMd layoutClassName={styles.paragraph} {...{ children }} />,
    heading: ({ children }) => <HeadingLg h={2} layoutClassName={styles.heading} {...{ children }} />,
    subheading: ({ children }) =>  <HeadingMd h={3} layoutClassName={styles.subheading} {...{ children }} />,
  },
  list: {
    bullet: ({ children }) => <ul className={cx(styles.list, styles.listUnordered)}>{children}</ul>,
    number: ({ children }) => <ol className={cx(styles.list, styles.listOrdered)}>{children}</ol>,
  },
  listItem: (props) => <li className={styles.listItem}>{props.children}</li>,
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    inspirationOverviewLink: ({ value, children }) => <InspirationOverviewLink {...{ value }}>{children}</InspirationOverviewLink>,
    externalLink: ({ value, children }) => <LinkUnderlined href={value?.href} target={value?.target} dataX='link'>{children}</LinkUnderlined>,
    internalLink: ({ value, children }) => value?.ref
      ? <LinkUnderlined href={determineDocumentPathSync({ document: value.ref, routeMap })} dataX='link'>{children}</LinkUnderlined>
      : <>{children}</>,
  },
}

function InspirationOverviewLink({ value, children }) {
  const language = useLanguage()
  const { inspirationTags: rawInspirationTags, contentTypes: rawContentTypes } = value || {}

  const inspirationTags = ensureArray(rawInspirationTags)
  const contentTypes = ensureArray(rawContentTypes)

  const queryString = stringifyQueryString({
    ...(inspirationTags.length && { tags: inspirationTags.map(x => x.slug.current) }),
    ...(contentTypes.length && { types: contentTypes })
  })

  return <LinkUnderlined href={`${routeMap.app.inspirations.index({ language })}?${queryString}`} target='_self' dataX='link'>{children}</LinkUnderlined>
}
