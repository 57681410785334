import { HeadingLg } from '/features/buildingBlocks/Heading'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { useElementSize } from '@kaliber/use-element-size'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import { TextMd } from '/features/buildingBlocks/Text'
import { Image } from '/features/buildingBlocks/Image'
import { useWithGroupedBlocksType } from '/machinery/useWithGroupedBlocksType'
import { useReportError } from '/machinery/ReportError'
import { extractLinkInfo } from '/machinery/extractLinkInfo'

import styles from './Faq.css'

export default function Faq({ questions, title, description, button = undefined }) {
  const [active, setActive] = React.useState(0)

  return (
    <div className={styles.component}>
      <div className={styles.description}>
        {title && <HeadingLg h={3}>{title}</HeadingLg>}
        {description && <TextMd layoutClassName={styles.description}>{description}</TextMd>}
        {button?.internalOrExternalLink &&
          <ButtonPrimary
            dataX='cta'
            href={extractLinkInfo(button.internalOrExternalLink).href}
          >
            {button.buttonLabel}
          </ButtonPrimary>}
      </div>
      <div className={styles.faq}>
        {Boolean(questions?.length) && questions.map((item, index) => {
          const key = `faq-question-${index}`
          return (
            <Item
              key={key}
              question={item.question}
              answer={item.answer}
              id={key}
              onActiveChange={() => handleActiveChange(item)}
              active={active === item}
            />
          )
        })}
      </div>
    </div>
  )

  function handleActiveChange(item) {
    setActive(x => x === item ? null : item)
  }
}

function Item({ question, answer, onActiveChange, active, id }) {
  const { size: { height }, ref: elementRef } = useElementSize()
  return (
    <div className={styles.componentItem}>
      <h3 className={styles.heading} data-x-context={question}>
        <button
          type='button'
          onClick={onActiveChange}
          className={cx(styles.toggle, active && styles.isActive)}
          data-x={active ? 'close-faq-item' : 'open-faq-item'}
          aria-controls={id}
          aria-expanded={active}
        >
          {question}
        </button>
      </h3>
      <div
        style={{ height: active ? height + 'px' : '0px' }}
        aria-hidden={!active}
        {... { id }}
        className={styles.answer}>
        <div className={styles.wrapper} ref={elementRef}>
          <div className={styles.inner}>
            <FAQAnswerContent {...{ answer, active }} />
          </div>
        </div>
      </div>
    </div>
  )
}

function FAQAnswerContent({ answer }) {
  const reportError = useReportError()
  const contentWithGroupedBlocks = useWithGroupedBlocksType(answer ?? [])

  return contentWithGroupedBlocks.map(x => {
    switch (x._type) {
      case 'blocks': return <PortableTextMainContent key={x._key} value={x.blocks} />
      case 'button': {
        const link = extractLinkInfo(x.internalOrExternalLink).href
        return link && (
          <ButtonPrimary key={x._key} dataX='link-in-faq-answer' href={link}>
            {x.buttonLabel}
          </ButtonPrimary>)
      }
      case 'image': return <Image key={x._key} image={x} />
      default: {
        reportError(new Error('Unknown content type ' + x._type))
        return null
      }
    }
  })
}
