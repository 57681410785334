export function useWithGroupedBlocksType(blocks) {
  return React.useMemo(() => groupItemsWithTypeBlock(blocks), [blocks])
}

function groupItemsWithTypeBlock(blocks) {
  return blocks.reduce(
    (result, x) => {
      if (x._type !== 'block') return [...result, x]

      const [lastItem] = result.slice(-1)
      if (!lastItem || lastItem._type !== 'blocks') return [...result, blocksType([x]) ]

      return [...result.slice(0, -1), blocksType([...lastItem.blocks, x])]
    },
    []
  )
}

function blocksType(blocks) {
  const [firstBlock] = blocks
  return { _type: 'blocks', _key: firstBlock._key, blocks }
}
